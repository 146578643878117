/** @jsx jsx */
import { jsx } from "theme-ui";

import { Heading, Layout, Link } from "components";

function NotFoundPage() {
  return (
    <Layout pageTitle="Not Found">
      <Heading>Not Found</Heading>

      <p>Sorry we couldn’t find what you were looking for.</p>
      <p>
        <Link to="/">Go home</Link>.
      </p>
    </Layout>
  );
}

export default NotFoundPage;
